import styles from './HeroSection.module.scss'
import { getImagePath } from '@/helpers/utils'
import Image from 'next/image'
import { FormattedMessage } from 'react-intl'

export default function HeroDivingAward() {
  return (
    <div className={styles.awardSection}>
      <Image src={getImagePath('readers-choice.png')}
        alt='Diving award'
        width={27}
        height={48}
      />
      <div className={styles.description}>
        <p className={styles.awardHeader}>
          <FormattedMessage defaultMessage='BEST DIVE TRAVEL AGENCY'
            tagName='span'
          />
        </p>
        <p>
          <FormattedMessage defaultMessage='Scuba Diving Magazine Readers Choice Award - Winners - 2022, 2023, 2024'
            tagName='span'
          />
        </p>
      </div>
      <Image src={getImagePath('readers-choice.png')}
        alt='Diving award'
        width={27}
        height={48}
      />
    </div>
  )
}
